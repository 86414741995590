html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 1rem;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  color: $black-color;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h2, h3,
h4,
h5,
h6 {
  margin-top: 20px;
  margin-bottom: 5px;
}
h1 {
  font-size: 50px;
  line-height: 1.15em;
  margin-bottom: 30px;
  color: $black !important;
  @media (max-width: 576px) {
    font-size: 30px;
  }
}
h2 {
  font-size: 26px;
  line-height: 1.5em;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4 {
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 500;
}
h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}
h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 14px;
  margin: 0 0 10px;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: #fff;
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

legend {
  border-bottom: 0;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: $checkboxes-text-color;
  font-weight: 400;
}
small {
  font-size: 75%;
  color: #777;
  font-weight: 400;
}
img {
  vertical-align: middle;
  border-style: none;
}
form {
  margin-bottom: 1.125rem;
}
// ############################################################

button {
  &.slick-arrow {
    &.slick-prev {
      text-align: left;
    }
    &.slick-next {
      text-align: right;
    }
    &.slick-prev, &.slick-next {
      &:before {
        color: #fff;
        text-shadow: 2px 2px 8px #000;
        font-size: 60px;
      }
    }
    
  }
}

// ############################################################
header {
  ul {
    li {
      font-size: 14px;
      text-transform: uppercase;
      
      a {
        color: #000 !important;
        padding: 1.4rem 2rem 0.5rem;
        display: inline-flex;
        position: relative;
        font-family: 'Lato';

        &.selected {
          color: $tnaa-purple !important;
          font-weight: 400;
        }
      }
    }
  }
}

// ############################################################
.content-container {
  ul {
    margin: 20px 0 30px;
    li {
      font-size: 18px;
      list-style: disc;
      list-style-position: outside;
      margin: 3px 0 3px 20px;
    }
  }
}
// ############################################################
.portfolio-client-name {
  font-size: 230px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  opacity: 0.5;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(155, 194, 211, 1);
  display: flex;
  align-items: center; 
  justify-content: space-between;
  letter-spacing: 28px;
  font-stretch: expanded;
  position: absolute;
  top: 201px;
  left: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &.aub {
    top: 312px;
    font-size: 220px;
    @media (max-width: 1150px) {
      font-size: 150px;
    }
    @media (max-width: 800px) {
      font-size: 80px;
    }
    @media (max-width: 600px) {
      top: 98px;
      left: 20px;
    }
    @media (max-width: 500px) {
      font-size: 50px;
    }
  }

  &.egs, &.bsc {
    font-size: 230px;
    width: 40%;
    top: 184px;
    left: 37%;
    @media (max-width: 900px) {
      left: 10%;
    }
    @media (max-width: 600px) {
      left: 10%;
      font-size: 150px;
      top: 0px;
    }
  }

  &.hcoa {
    font-size: 230px;
    width: 80%;
    top: 184px;
    left: 15%;
    @media (max-width: 900px) {
      left: 10%;
      font-size: 185px;
    }
    @media (max-width: 600px) {
      left: 10%;
      font-size: 130px;
      top: 0px;
    }
    @media (max-width: 375px) {
      left: 20px;
      font-size: 110px;
    }
  }

  &.acessa {
    width: 80%;
    left: 15%;
    bottom: -54px;
    @media (max-width: 1260px) {
      bottom: 0;
    }
  }

  &.about {
    width: 90%;
    left: 10%;
    bottom: -54px;
    @media (max-width: 1260px) {
      bottom: 0;
    }
  }
  
  @media (max-width: 1260px) {
    font-size: 115px;
  }
  @media (max-width: 715px) {
    font-size: 90px;
    letter-spacing: 28px;
  }
  @media (max-width: 600px) {
    font-size: 70px;
    letter-spacing: 5px;
    justify-content: flex-start;
  }
  @media (max-width: 375px) {
    width: 80%;
  }

  > span {
    display: block;
    flex: 1 1 auto;
    transform:scale(1.2,1); 
    -webkit-transform:scale(1.2,1);
  }
}
.portfolio-title {
  font-size: 50px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 320px;
  left: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  letter-spacing: 15px;

  &.aub {
    top: 370px;
    color: $tnaa-light-blue;
    @media (max-width: 600px) {
      top: 226px;
      left: 0px;
      width: 100%;
      font-size: 33px;
    }
    @media (max-width: 450px) {
      font-size: 20px;
    }
  }

  &.egs {
    top: 310px;
    left: 210px;
    text-align: left;
    color: $tnaa-black;
    @media (max-width: 715px) {
      color: #fff;
      font-size: 30px;
    }
    @media (max-width: 600px) {
      top: 200px;
      left: 50px;
    }
  }
  &.hcoa, &.bsc, &.acessa {
    top: 290px;
    left: 100px;
    width: 65%;
    line-height: 1.1em;
    letter-spacing: 15px;
    text-align: left;
    color: #fff;
    @media (max-width: 900px) {
      font-size: 30px;
      width: 70%;
    }
    @media (max-width: 600px) {
      font-size: 20px;
      width: 80%;
      top: 190px;
      left: 50px;
      // color: $tnaa-black;
      text-shadow: 1px 1px 5px $tnaa-black;
    }
    @media (max-width: 375px) {
      width: 100%;
      left: 20px;
    }
  }
  &.bsc {
    width: 80%;
    letter-spacing: 5px;
    @media (max-width: 600px) {
      font-size: 15px;
    }
  }
  @media (max-width: 1260px) {
    top: 280px;
    width: 100%;
    letter-spacing: 10px;
  }
  @media (max-width: 715px) {
    top: 260px;
    font-size: 30px;
    letter-spacing: 5px;
    width: 80%;
  }
  @media (max-width: 600px) {
    top: 170px;
    font-size: 20px;
    letter-spacing: 5px;
    width: 60%;
  }
}
// Client specific parrallax header images:
.mobile-home {
  z-index: 0;
  max-width: 100%;
  height: 300px;
  background-position-x: 50% !important;
  background-position-y: 35% !important;
  
  @media (max-width: 768px) {
    height: 300px !important;
    .poster-text-box {
      & > div {
        display: inline !important;
        font-size: 16px !important;
        top: 25px !important;
        width: 260px !important;
        height: auto !important;
        padding: 2rem !important;
      }
    }
    .poster-brand {
      text-align: center;
      margin-top: 20px;
      & > img {
        max-width: 65% !important;
      }
    }
  }
  @media (min-width: 769px) and (max-width: 970px) {
    height: 450px !important;
    .poster-text-box {
      & > div {
        display: inline !important;
        font-size: 18px !important;
        top: 125px !important;
        width: 260px !important;
        height: auto !important;
        padding: 1.5rem !important;
      }
    }
  }
  @media (min-width: 971px) and (max-width: 1140px) {
    height: 500px !important;
    .poster-text-box {
      & > div {
        display: inline !important;
        font-size: 18px !important;
        top: 185px !important;
        width: 260px !important;
        height: auto !important;
        padding: 1.5rem !important;
      }
    }
  }
  @media (min-width: 1141px) and (max-width: 1440px) {
    height: 500px !important;
    .poster-text-box {
      & > div {
        display: inline !important;
        font-size: 18px !important;
        top: 220px !important;
        width: 260px !important;
        height: auto !important;
        padding: 1.5rem !important;
      }
    }
  }
}

// ############################################################
// HOME PARALLAX
#hero {
	height: 600px;
	overflow: hidden;
	position: relative;
}
.home-layer {
	width: 100%;
	height: 600px;
	position: fixed;
	z-index: -1;
}
#hero, .home-layer {
	min-height: 600px;
  @media (max-width: 768px) {
    min-height: 100vh;
    max-height: 100vh;
  }
}
.home-layer-bg {
  background-color: $tnaa-purple;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  animation: slides 25s linear infinite;
    -webkit-animation: slides 25s linear infinite;
    -moz-animation: slides 25s linear infinite;
    -o-animation: slides 25s linear infinite;
    -ms-animation: slides 25s linear infinite;
}
@-webkit-keyframes slides {
  from {
      background: url("../../img/marquee/TNAA-marquee-neon2.jpg");
      background-size: cover;
      background-position: center center;
  }
  25% {
      background: url("../../img/marquee/TNAA-marquee-neon3.jpg");
      background-size: cover;
      background-position: center center;
  }
  50% {
      background: url("../../img/marquee/TNAA-marquee-neon4.jpg");
      background-size: cover;
      background-position: center center;
  }
  75% {
      background: url("../../img/marquee/TNAA-marquee-neon6.jpg");
      background-size: cover;
      background-position: center center;
  }
  to {
      background: url("../../img/marquee/TNAA-marquee-neon2.jpg");
      background-size: cover;
      background-position: center center;
  }
}
@keyframes slides {
  from {
    background: url("../../img/marquee/TNAA-marquee-neon2.jpg");
    background-size: cover;
    background-position: center center;
  }
  25% {
      background: url("../../img/marquee/TNAA-marquee-neon3.jpg");
      background-size: cover;
      background-position: center center;
  }
  50% {
      background: url("../../img/marquee/TNAA-marquee-neon4.jpg");
      background-size: cover;
      background-position: center center;
  }
  75% {
      background: url("../../img/marquee/TNAA-marquee-neon6.jpg");
      background-size: cover;
      background-position: center center;
  }
  to {
      background: url("../../img/marquee/TNAA-marquee-neon2.jpg");
      background-size: cover;
      background-position: center center;
  }
}
.logo-left, .logo-right {
  height: 100px;
}
.logo-wrapper-advag {
  width: 260px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  top: 40%;
  @media (max-width: 375px) {
    top: 34%;
  }
  img {
    max-width: 100%;
  }
}
.logo-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  text-align: center;
  top: 20%;
  @media (max-width: 375px) {
    top: 14%;
  }
}
.tagline {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  top: 59%;
  padding-top: 20px;
  @media (max-width: 375px) {
    top: 54%;
  }
  span {
    color: $white;
    font-size: 25px;
    font-weight: 800;
    font-family: 'Tahoma';
    padding-right: 30px;
    @media (max-width: 540px) {
      font-size: 20px;
      padding-right: 8px;
    }
    @media (max-width: 375px) {
      font-size: 18px;
    }
    &.last {
      padding-right: 0;
    }
  }
}
.home-marquee-content {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align: center;
  top: 70%;
  padding-top: 20px;
  color: $white;
  font-size: 20px;
  font-weight: 800;
  font-family: 'Roboto';
  line-height: 28px;
  @media (min-width: 1024px) {
    width: 50%;
  }
  @media (max-width: 375px) {
    font-size: 17px;
    top: unset;
    bottom: 7%;
  }
}
#difference, #work {
  background-color: $white;
  a {
    font-weight: 400;
  }
}
.down-arrow {
  border: solid $white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 10px;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 25px;
  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  
  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  
  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  
  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

// ############################################################
// Home animation: differences
#animation {
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;
  margin-top: 80px;
  > div {
    flex: 1 0 auto;
    min-height: 300px;
    background-repeat: no-repeat !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    padding-top: 80px;
    @media (max-width: 450px) {
      padding-top: 20px;
      line-height: 20px;
    }
    color: $black-color;
    @media (max-width: 450px) {
      // font-size: 12px;
    }
    &.anim-left {
      background: url("../../img/logo-blue-left.svg");
      background-position-x: right;
      margin-right: -50px;
      position: relative;
    }
    &.anim-right {
      background: url("../../img/logo-pink-right.svg");
      background-position-x: left;
      margin-left: -50px;
      text-align: right;
      position: relative;
    }
    &.anim-left, 
    &.anim-right {
      @media (max-width: 450px) {
        margin-right: 0px;
        background-size: 100%;
        ul {
          max-width: 150px;
          margin: 0;
          position: absolute;
          li {
            padding: 7px 0;
          }
        }
      }
    }
    &.anim-left {
      @media (max-width: 375px) {
        margin-right: -10px;
      }
      ul {
        left: 0;
      }
    }
    &.anim-right {
      @media (max-width: 375px) {
        margin-left: -10px;
      }
      ul {
        right: 0;
      }
    }
  }
}

// ############################################################
// About - Team
.about-team {
  margin-bottom: 100px;
  @media (min-width: 1024px) {
    float: left;
    margin-right: 130px;
  }
  .team-member {
    background-repeat: no-repeat !important;
    background-size:415px;
    width: 315px;
    height: 530px;
    cursor: pointer;
    @media (max-width: 375px) {
      width: auto;
    }
    
    &.jane {
      background: url("../../img/about/jane.jpg");
    }
    &.carol {
      background: url("../../img/about/carol.jpg");
    }
    &.xavier {
      background: url("../../img/about/xavier.jpg");
    }
    &.bob {
      background: url("../../img/about/bob.jpg");
    }
    &.active {
      width: 415px !important;
      @media (max-width: 375px) {
        width: auto !important;
      }
    }
  }
  .bio {
    font-size: 18px;
    line-height: 28px;
    margin: 12px 0 100px;
    max-width: 390px;
    .bio-wrapper {
      display: none;
    }

    &.active {
      .bio-wrapper {
        display: block;
      }
    }

    .intro {
      margin: 0 0 10px;
      .readmore {
        cursor: pointer;
        text-align: center;
        font-weight: bold;
        color: $tnaa-darkpurple;
        &:before {
          content: '+ ';
          padding-right: 10px;
          font-size: 20px;
        }
        &.hide {
          display: none;
        }
      }
    }
    .more {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}
.grid-container {
  margin-bottom: 100px;
}


.marquee-home {
  @media (max-width: 600px) {
    background-position-x: -276px !important;
    background-size: 1000px !important;
    height: 250px !important;
  }
  @media (max-width: 400px) {
    background-size: 840px !important;
  }
  .portfolio-client-name {
    @media (max-width: 600px) {
      top: 125px !important;

    }
  }
}
.aub {
  @media (max-width: 600px) {
    background-position-x: -276px !important;
    background-size: 1000px !important;
    height: 250px !important;
  }
  @media (max-width: 450px) {
    background-size: 775px !important;
    background-position-x: -206px !important;
  }
  .portfolio-client-name {
    @media (max-width: 600px) {
      //top: 125px !important;
    }
  }
}
.minerva-consumer {
  @media (max-width: 600px) {
    background-position-x: -276px !important;
    background-size: 1000px !important;
    height: 250px !important;
  }
  @media (max-width: 400px) {
    background-size: 840px !important;
  }
  .portfolio-client-name {
    @media (max-width: 600px) {
      top: 125px !important;

    }
  }
}
.egs {
  @media (max-width: 950px) {
    background-position-x: -800px !important;
    background-size: 1600px !important;
  }
  @media (max-width: 600px) {
    background-position-x: -800px !important;
    background-position-y: -20px !important;
    background-size: 1500px !important;
    height: 250px !important;
  }
}
.hcoa {
  @media (max-width: 900px) {
    background-position-x: -800px !important;
    background-size: 1800px !important;
  }
  @media (max-width: 600px) {
    background-position-x: -900px !important;
    background-size: 1500px !important;
    height: 250px !important;
  }
}
.bsc {
  @media (max-width: 600px) {
    height: 250px !important;
  }
}
.acessa {
  @media (max-width: 1024px) {
    background-position-x: -220px !important;
  }
  @media (max-width: 600px) {
    background-position-x: -140px !important;
    background-size: 800px !important;
    height: 250px !important;
  }
}
.about {
  @media (max-width: 1024px) {
    background-position-x: -220px !important;
  }
  @media (max-width: 600px) {
    background-position-x: -140px !important;
    background-size: 800px !important;
    height: 250px !important;
  }
}


// ############################################################


.portfolio-sub-title, .vertical-text {
  font-size: 100px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  opacity: 0.5;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgba(155, 194, 211, 1);
  line-height: 1em;
  margin: 50px 0 0;
}
.vertical-holder {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  
  &.story {
    background: transparent url('../../img/work/shared/story.png') no-repeat;
    background-size: 225px;
    background-position-x: right;
    background-position-y: center;
  }
  &.landing {
    background: transparent url('../../img/work/shared/landing.png') no-repeat;
    background-size: 225px;
    background-position-x: right;
    background-position-y: center;
  }
  &.print {
    background: transparent url('../../img/work/shared/print.png') no-repeat;
    background-size: 225px;
    background-position-x: left;
    background-position-y: center;
  }
  &.outreach {
    background: transparent url('../../img/work/shared/outreach.png') no-repeat;
    background-size: 225px;
    background-position-x: right;
    background-position-y: center;
  }
  &.cta {
    background: transparent url('../../img/work/shared/cta.png') no-repeat;
    background-size: 225px;
    background-position-x: left;
    background-position-y: center;
  }
  &.concepts {
    background: transparent url('../../img/work/shared/concepts.png') no-repeat;
    background-size: 225px;
    background-position-x: left;
    background-position-y: center;
  }
  &.consumer-education {
    background: transparent url('../../img/work/shared/consumer-education.png') no-repeat;
    background-size: 225px;
    background-position-x: left;
    background-position-y: center;
  }
  &.social {
    background: transparent url('../../img/work/shared/social.png') no-repeat;
    background-size: 225px;
    background-position-x: left;
    background-position-y: center;
  }
  &.top {
    background-position-y: top;
  }
  @media (max-width: 600px) {
    background: none !important;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  .item {
    text-align: center;
    max-width: 325px;
    margin: 0 20px 20px;
    &.stretch {
      max-width: 100%;
    }
    &.ninexaub {
      margin-bottom: 100px;
    }
    &.event1 {
      margin-bottom: 150px;
      max-width: 80%;
    }
    &.event2 {
      max-width: 60%;
    }
    &.social-phones {
      margin-top: 100px;
      margin-bottom: 200px;
    }
    &.iphone {
      max-width: 250px;
    }
    &.brochure {
      max-width: 900px;
      @media (max-width: 768px) {
        max-width: 600px;
      }
    }
    &.longscreens {
      max-width: 730px;
      @media (max-width: 768px) {
        max-width: 450px;
      }
    }
    &.questions {
      max-width: 730px;
      @media (max-width: 768px) {
        max-width: 500px;
      }
    }
    &.concept {
      max-width: 1000px;
    }
    &.adfull {
      max-width: 1000px;
    }
    &.ad {
      max-width: 325px;
    }
  }
}

.ads {
  padding-bottom: 100px;
  &.ads-np {
    padding-bottom: 0;
  }
  .ad {
    margin: 0 20px 20px;
    padding: 20px 20px 0 0;
  }
}

// ############################################################

.quick-links {
  text-align: right;
  button {
    & > span {
      font-size: 18px;
    }
  }
  & button > span {
    text-transform: capitalize;
    font-weight: bold;
    & > b {
      color: $tnaa-purple;
      margin-left: 10px;
    }
  }
  & li {
    a {
      color: $tnaa-black;
      display: block;
      font-size: 18px;
    }
  }
}

.carousel-portfolio {
  margin-top: 10px !important;
}

.snackbar-alert {
  font-size: 16px;
}

.loader-wrapper {
  background-image:
    linear-gradient(
      to right, 
      rgb(120,120,120), 
      #ffffff, 
      rgb(120,120,120)
    );
}
img.video-poster {
  max-width: 100%;
  height: 100%;
}

.fadein {
  position:relative;
  margin:auto;
  padding: 0;
  height: 800px;
  width: 100%;
  display: block;

  @media (max-width: 1279px) {
    height: 640px;
  }
  @media (max-width: 1024px) {
    height: 600px;
  }
  @media (max-width: 767px) {
    height: 200px;
  }
  
  > img {
    position:absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 18s;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 18s;

    &#f2 {
      -webkit-animation-delay: -3s;
    }
    &#f3 {
      -webkit-animation-delay: -6s;
    }
    &#f4 {
      -webkit-animation-delay: -9s;
    }
    &#f5 {
      -webkit-animation-delay: -12s;
    }
    &#f6 {
      -webkit-animation-delay: -15s;
    }
  }
  h1 {
    position: absolute;
    z-index: 99;
  }
}

@-webkit-keyframes fade {
  0% {opacity: 0;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  53% {opacity: 0;}
  100% {opacity: 0;}
}
@keyframes fade {
  0% {opacity: 0;}
  20% {opacity: 1;}
  33% {opacity: 1;}
  53% {opacity: 0;}
  100% {opacity: 0;}
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

footer {
  padding: 0 0 20px;
  text-align: left;
  z-index: 2;
  position: relative;
  background-color: #4A164B;
  min-height: 200px;
  display: flex;

  a {
    padding: 0 0.9375rem;
    white-space: nowrap;
    letter-spacing: 1.4px;
    font-family: 'Lato';
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
    &:hover, &:focus {
      color: #fff;
    }
    &.break-text {
      white-space: normal;
    }
    &.sep {
      border-right: 1px solid $white;
    }
  }

  .footer {
    color: #fff;

    address {
      font-style: normal;
      margin: 14px 0;
    }
  
    &.footer-links {
      ul {
        li {
          text-align: center;
          padding: 0;
          display: block;
        
        }
      }
    }
  
    &.copyright {
      margin-left: auto;
      ul {
        margin-top: 10px;
        li {
          text-align: center;
        }
      }
    }
  }
}

.margin-bottom-big {
  margin-bottom: 150px;
}
.padding-bottom-big {
  padding-bottom: 150px;
}